.content {
  margin: auto;
  width: 1280px;
}

.buttonContainer {
  float: right;
}

.buttonContainer button {
  font-size: 1em
}

#root {
  height: 100%;
}

html, body {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
  padding: 0.8rem;
  width: 1280px;
  margin: auto;
}
