.loadingOverlay {
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 80%;
  left: 0;
  top: 0;
}

.loadingOverlay__text {
  font-size: 1.4rem;
  text-align: center;
  color: #fff;
  opacity: 100%;
  margin: 15.6rem auto;
}
