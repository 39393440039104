.header {
  background-color: #1db954;
  padding: 1rem;
  margin-bottom: 0.6rem;
}

.header__content {
  margin: auto;
  max-width: 1280px;
  height: 1.6em;
}

.header__title {
  color: #fff;
  font-weight: 700;
  font-size: 1.2em;
  display: block;
  float: left;
}

.header__logo {
  margin: auto;
  margin-right: 0.6rem;
  width: 1.6rem;
  display: block;
  float: left;
}

.header__dragPrompt {
  color: #fff;
  font-size: 1em;
  display: block;
  float: left;
  margin-left: 1.2em;
  margin-top: 0.2em;
}
