.releasesTable {
  width: 100%;
}

.releasesTable__head {
  font-weight: bold;
}

.releasesTable__headColumn {
  padding-bottom:0.6em;
  text-align: left;
  background: #fff;
  position: sticky;
  z-index: 1;
  top: 0;
}

.releasesTable__headColumn__artwork {
  padding-right: 5em;
}

.releasesTable__column {
  padding: 0.2rem;
}

.releasesTable__albumArtwork {
  display: block;
}

.releasesTable__trackName {
  font-weight: 550;
}

.releasesTable__merchantLink {
  margin-right: 0.2rem;
  font-size: 0.9rem;
}

.releasesTable__merchantsColumn {
  word-break: break-all;
  width: 16em;
  padding: 0.6em;
}

.releasesTable__tbody .releasesTable__row:nth-child(odd) {
  background-color: #f2f2f2;
}

.releasesTable__boughtColumn {
  width: 4.6rem;
  text-align: center;
}

.releasesTable__boughtCheckbox {
  transform: scale(1.5);
}

.releasesTable__artistsColumn {
  min-width: 10rem;
  font-weight: 500;
}

.releasesTable__albumColumn {
  min-width: 14rem;
  font-weight: 500;
}

.releasesTable__albumName {
  font-weight: 500;
}

.releasesTable__albumLabel {
  font-size: 0.8em;
}

.releasesTable__albumLabel__label {
  font-weight: 450;
}

.releasesTable__albumTracksList {
  list-style: none;
  font-size: 0.8em;
}
